import { InboxIcon } from 'lucide-react';
import * as React from 'react';
import { useId } from 'react';

import { cn } from '~/libs/utils';

interface CardContextValue {
  id: string;
}

const CardContext = React.createContext<CardContextValue>({} as CardContextValue);

const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ ...props }, ref) => {
  const id = useId();
  return (
    <CardContext.Provider value={{ id }}>
      <CardRoot ref={ref} {...props} />
    </CardContext.Provider>
  );
});
Card.displayName = 'Card';
const useCard = () => {
  const itemContext = React.useContext(CardContext);

  const { id } = itemContext;

  return {
    id,
    cardTitleId: `${id}-card-title`,
    cardDescriptionId: `${id}-card-description`,
  };
};

const CardRoot = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { cardTitleId, cardDescriptionId } = useCard();
    return (
      <div
        data-component-type='card'
        aria-labelledby={cardTitleId}
        aria-describedby={cardDescriptionId}
        ref={ref}
        role='presentation'
        className={cn(
          'border-card-foreground/20 flex flex-col rounded-lg border bg-card text-card-foreground shadow transition-all hover:shadow-lg',
          className
        )}
        {...props}
      />
    );
  }
);
CardRoot.displayName = 'CardRoot';

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      data-component-type='card-header'
      ref={ref}
      className={cn('flex flex-col items-center space-y-2 p-6', className)}
      {...props}
    />
  )
);
CardHeader.displayName = 'CardHeader';

const CardIcon = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <h3
      data-component-type='card-icon'
      ref={ref}
      className={cn('text-2xl font-semibold leading-none tracking-tight', className)}
      {...props}
    />
  )
);
CardIcon.displayName = 'CardIcon';

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => {
    const { cardTitleId } = useCard();

    return (
      <h3
        id={cardTitleId}
        data-component-type='card-title'
        ref={ref}
        className={cn('text-2xl font-semibold leading-none tracking-tight', className)}
        {...props}
      />
    );
  }
);
CardTitle.displayName = 'CardTitle';

const CardDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => {
    const { cardDescriptionId } = useCard();
    return (
      <p
        id={cardDescriptionId}
        data-component-type='card-description'
        ref={ref}
        className={cn('text-sm text-muted-foreground', className)}
        {...props}
      />
    );
  }
);
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      data-component-type='card-content'
      ref={ref}
      className={cn('flex flex-col gap-8 p-6 pt-0', className)}
      {...props}
    />
  )
);
CardContent.displayName = 'CardContent';

const CardEmptyContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div
      data-component-type='card-empty-content'
      className={cn('flex h-48 flex-col items-center justify-center', className)}
      ref={ref}
      {...props}
    >
      <InboxIcon className='w-12 h-12 text-accent mb-4' />
      <p className='text-lg font-medium text-foreground'>{children}</p>
    </div>
  )
);
CardEmptyContent.displayName = 'CardEmptyContent';

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      data-component-type='card-footer'
      ref={ref}
      className={cn('flex items-center p-6 pt-0', className)}
      {...props}
    />
  )
);
CardFooter.displayName = 'CardFooter';

export { Card, CardHeader, CardFooter, CardIcon, CardTitle, CardDescription, CardContent, CardEmptyContent };
